.AgentDashboard {
  &__main-container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    max-width: 1366px;
    @media screen and (max-width: 1025px) {
      margin-left: unset;
      align-items: center;
      max-width: unset;
    }
  }
}
