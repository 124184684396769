.App {
  font-size: 14px;
  background: #f6f9fc;
}

.General-main-heading {
  color: #e79066;
  background: #edb89e57;
  line-height: 1.5;
  padding: 6px 10px;
  border-radius: 4px;
  @media screen and (max-width: 400px) {
    padding: 2px;
  }
}

.main-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  max-width: 1366px;
  @media screen and (max-width: 450px) {
    min-width: 96%;
    padding: 60px 6px;
    margin-left: unset;
    max-width: unset;
  }
}
