.Statistics {
  &__main-container {
    padding: 60px 15px;
    max-width: 450px;
    @media screen and (max-width: 450px) {
      padding: 6px;
    }
  }

  &__donough {
    max-width: 300px;
    margin: 40px auto;
  }
}
