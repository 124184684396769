.Login {
  &__main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    height: 100vh;
    // overflow: auto;
    // background: rgba(0, 0, 0, 0.54118);
  }

  &__paper {
    margin: auto;
    width: 500px;
    @media screen and (max-width: 500px) {
      width: unset;
      max-width: 96%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__sub-container {
    height: auto;
    width: 450px;
    margin: 50px auto;
    @media screen and (max-width: 1000px) {
      padding: 0;
      width: 95%;
      margin: auto;
    }
  }

  &__logo-image {
    width: 450px;
    height: 250px;
    display: block;
    margin: 50px auto 0;
    @media screen and (max-width: 700px) {
      width: 90%;
    }
  }

  &__logo-name {
    font-size: 40px;
    text-align: center;
    font-family: "Courgette", cursive;
  }

  &__logo-caption {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    text-align: center;
    color: #687980;
  }

  &__login-header {
    font-size: 24px;
    margin: 30px;
    // font-weight: 600;
    // text-transform: uppercase;
    color: #42444d;
    font-family: "Montserrat";
  }

  &__wrong-password {
    color: red;
    font-size: 15px;
    margin: 30px;
  }

  &__success-password {
    font-weight: 500;
    font-size: 20px;
    color: red;
  }

  &__col-3 {
    margin: 30px;
    position: relative;
    @media screen and (max-width: 700px) {
      margin: 10px;
    }
  }

  &__signup {
    margin: 30px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: chocolate;
    font-weight: 600;
    @media screen and (max-width: 700px) {
      margin: 10px;
      padding-top: 20px;
    }
  }

  &__show-password {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 10px;
    cursor: pointer;
  }

  &__submit {
    height: 48px;
    width: 100%;
    border-radius: 4px;
    background-color: #4267b2; // #df711b;
    background: linear-gradient(278deg, #71bf45, #37a4db);
    border: none;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    margin-top: 32px;
    cursor: pointer;
    &-disabled {
      background-color: #808080;
      cursor: not-allowed;
    }
  }

  &__input-focus-effect {
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
  }

  &__input-focus-effect ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #3399ff;
    transition: 0.4s;
  }
  &__input-focus-effect:focus ~ .focus-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
  }
}

:focus {
  outline: none;
}

input {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
