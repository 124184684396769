.OrdersCancel {
  &__main-container {
    padding: 30px;
  }

  &__row-class {
    padding-bottom: 15px;
    margin: auto;
    width: 40%;
    border: 3px;
    padding: 10px;
  }

  &__dateside {
    padding-right: 20px;
  }

  &__edit {
    margin: auto;
    border: 3px;
    padding: 10px;
    text-align: center;
    color: #0b3863af;
    background-color: #b6c6d4af;
    border-radius: 6px;
    width: 85px;
    cursor: auto;
    font-weight: 600;
   
  }
  &__revertedit {
    
    color: #0b3863af;
    background-color: #b6c6d4af;
    border-radius: 6px;
    width: 40%;
    cursor: auto;
    font-weight: 600;
    margin: auto;
    border: 3px;
    padding: 10px;
    text-align: center;
  }

}
