@import url("https://fonts.googleapis.com/css?family=Varela+Round");
.Navbar {
  &__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #90a0ee;
    position: fixed;
    height: 50px !important;
    overflow: hidden;
    z-index: 2;
    box-shadow: 0px 4px 16px rgb(43 52 69 / 60%);
  }
  &__sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    left: 15px;
    height: 22px;
    width: 22px;
    margin-top: -7px;
  }

  &__sidebarMenu {
    height: calc(94vh - 60px);
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 12px;
    width: 200px;
    margin-top: 40px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background: linear-gradient(180deg, #90a0ee 0%, #74b3e8 100%);
  }

  &__sidebarMenu-inner {
    margin: 0;
    padding: 0;
    border-top: 1px solid #ffffff;
  }
  &__sidebarMenu-inner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #ffffff;
  }

  &__sideMenu-icons {
    display: flex;
    align-items: center;
  }

  &__sideMenu-icons-img {
    margin-left: 20px;
  }

  &__sidebarMenu-header {
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    text-align: center;
    span {
      font-size: 14px;
    }
  }

  &__logout-container {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
  }

  &__logout-text {
    font-size: 10px;
    color: white;
  }

  &__logout-image {
    text-decoration: none;
    float: right;
    width: 25px;
    border-radius: 50px;
    float: left;
  }

  &__component-name {
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
}

input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
