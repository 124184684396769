.Users {
  &__main-container {
    // display: flex;
    // padding: 15px;
    display: block;
    @media screen and (max-width: 450px) {
      padding: 6px;
    }
  }

  &__sub-agent-container {
    height: 430px;
    width: 500px;
    margin: 50px auto;
    @media screen and (max-width: 1000px) {
      padding: 0;
      height: 50vh;
      width: 95vw;
      margin: auto;
    }
  }

  &__sub-container {
    height: 430px;
    width: 500px;
    margin: 50px auto;
    @media screen and (max-width: 1000px) {
      padding: 0;
      height: 100vh;
      width: 95vw;
      margin: auto;
    }
  }

  &__logo-image {
    width: 400px;
    height: 200px;
    display: block;
    margin: auto;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__errors {
    color: red;
  }

  &__date-address-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    // padding-bottom: 7px;
  }

  &__date-ringbell-row {
    display: flex;
    justify-content: space-between;
    
    // padding-bottom: 7px;
  }

  &__spinner {
    // width: 80px !important;
    // height: 80px !important;
    margin-top: 150px;
    animation: spin 3s infinite linear;
    transform-origin: 50% 63.67%; // (vertical center) / (image height) * 100 = (percentage)
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  &__customername {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 2px solid #80808066;
  }

  &__right-column {
    text-align: left;
  }

  &__products-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__refresh-button {
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.75;
    padding: 6px 15px;
    border-radius: 4px;
    color: rgb(210, 63, 87);
    font-weight: 600;
    text-transform: capitalize;
    min-width: 0px;
    min-height: 0px;
    background-color: rgb(252, 233, 236);

    &-disabled {
      cursor: not-allowed;
    }
  }

  &__order {
    // flex: 0 0 400px;
    flex: 0 0 355px;
    color: #171e42;
    padding: 10px 30px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    @media screen and (max-width: 450px) {
      flex: 0 0 96%;
      margin-left: 0px;
      padding: 10px 5px;
    }
  }

  &__top {
    max-width: 400px;
    // height: 100px;
    margin: 30px;
    padding: 10px;
    @media screen and (max-width: 450px) {
      margin: 1px;
    }
  }

  &__fromdate {
    padding: 10px;
  }

  &__searchuser {
    width: 50%;
  }
}

