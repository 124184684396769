.MiniNavbar {
  &__main {
    display: flex;
    justify-content: space-around;
    border: 2px solid orange;
    padding: 5px;
    max-width: 410px;
    min-width: 96%;
    margin: auto;
    @media screen and (max-width: 450px) {
      // position: fixed;
      border: none;
      border-top: 2px solid orange;
      bottom: 0px;
      background: white;
      font-size: 14px;
      margin: 6px;
    }
  }
  &__flexcenter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }
}
