.Settings {
  &__main-container {
    width: 400px;
    margin: auto;
    padding: 59px 15px;
    max-width: 450px;
    @media screen and (max-width: 450px) {
      width: 96%;
    }
  }

  &__heading-container {
    display: flex;
    cursor: pointer;
  }

  &__heading-left {
    text-align: center;
    height: 33px;
    width: 180px;
    border-radius: 22px;
    background-color: #d3d3d34d;
    border: 2px solid black;
    font-size: 16px;
    line-height: 31px;
    margin: 10px auto 0;
    &-selected {
      background-color: #90a0ee;
      color: white;
    }
  }

  &__heading-right {
    text-align: center;
    height: 33px;
    width: 180px;
    border-radius: 22px;
    background-color: #d3d3d34d;
    border: 2px solid black;
    font-size: 16px;
    line-height: 31px;
    margin: 10px auto 0;
    &-selected {
      background-color: #90a0ee;
      color: white;
    }
  }

  &__field-container {
    margin: 15px 0;
  }

  &__label {
    padding-left: 5px;
  }

  &__firstname {
    border: 2px solid #d3d3d34d;
    border-radius: 8px;
    height: 39px;
    transition: 0.4s;
    margin-top: 6px;
  }

  &__firstname:focus {
    width: 100%;
    transition: 0.4s;
    border: 2px solid pink;
    left: 0;
  }

  &__email {
    border: 2px solid #d3d3d34d;
    background-color: #d3d3d34d;
    border-radius: 8px;
    height: 39px;
    transition: 0.4s;
    margin-top: 6px;
  }

  &__email:focus,
  &__password:focus {
    width: 100%;
    transition: 0.4s;
    border: 2px solid pink;
    left: 0;
  }

  &__password {
    border: 2px solid #d3d3d34d;
    border-radius: 8px;
    height: 39px;
    transition: 0.4s;
    margin-top: 6px;
  }

  &__submit {
    width: 300px;
    border-radius: 50px;
    background-color: #4267b2; // #df711b;
    border: none;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    height: 39px;
    margin: auto;
    display: block;
  }
}
