.AssignUsers {
  &__main {
    // display: flex;
    padding: 15px;
    @media screen and (max-width: 450px) {
      padding: 6px;
      width: 100%;
    }
  }

  &__sub {
    text-align: center;
    max-width: 550px;
    margin: 30px;
    padding: 30px;
    position: relative;
    @media screen and (max-width: 700px) {
      margin: 30px 0;
      padding: 30px 0;
    }
  }
}
