.Deliveries {
  &__main-container {
    display: block;
    min-width: 400px;
    padding: 15px;
    @media screen and (max-width: 700px) {
      width: 96%;
      min-width: unset;
    }
  }

  &__add-button {
    background: transparent;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    &-disabled {
      cursor: not-allowed;
    }
  }

  &__types-container {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
  }

  &__types-container-img {
    display: block;
  }

  &__types-svg {
    cursor: pointer;
    margin: 10px;
  }

  &__edit-input {
    margin: 10px 0;
  }

  &__status {
    cursor: pointer;
    color: rgb(192, 103, 144);
    font-weight: bold;
  }

  &__products-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 2px solid #80808066;
    padding-top: 7px;
  }
}
